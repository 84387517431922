import { useEffect } from "react";
import WOW from "wowjs";
import BusinessGrowLearnMore from "./Pages/BusinessGrowLearnMore";
import CarouselLearnMore from "./Pages/CarouselLearnMore";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import "./styles/crowsel.css";
import "./styles/bundle.min.css";
import "./styles/style.css";
import "./styles/cubeportfolio.min.css";
import "./styles/custom.css";
import "./styles/LineIcons.min.css";
import "./styles/owl.carousel.min.css";
import "./styles/settings.css";
import "./styles/swiper.min.css";
import "./styles/wow.css";
import "./App.css";
import Loader from "./Pages/Loader";
import Navbar from "./Pages/Navbar";
import Footer from "./Pages/Footer";
import { ObserverProvider } from "./Contexts/ObserverContext";
import ErpServices from "./Pages/ErpServices";
import DatabaseAdminServices from "./Pages/DatabaseAdminServices";
import CustomisedApplicationDev from "./Pages/CustomisedApplicationDev";
import SuccessStoriesReadMore from "./Pages/SuccessStoriesReadMore";
import Contact from "./Pages/Contact";

function App() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  });
  
  return (
    <>
      <ObserverProvider>
        <Loader></Loader>
        <Navbar></Navbar>
        <BrowserRouter>
          <Routes>
            <Route path="/" Component={Home}></Route>
            <Route path="/about-us" Component={CarouselLearnMore}></Route>
            <Route
              path="/our-services"
              Component={BusinessGrowLearnMore}
            ></Route>
            <Route path="/erp-services" Component={ErpServices}></Route>
            <Route path="/database-services" Component={DatabaseAdminServices}></Route>
            <Route path="/custom-development" Component={CustomisedApplicationDev}></Route>
            <Route path="/read-more/:type" Component={SuccessStoriesReadMore}></Route>
          </Routes>
        </BrowserRouter>
        <Contact></Contact>
        {/* <Footer></Footer> */}
      </ObserverProvider>
    </>
  );
}

export default App;
